import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { graphql } from "gatsby"
import {
  Container,

  SwiperContainer,
  Wrapper,
  Header,
  HeaderTitle,
  Like,
  Produktbeschreibung,

  ConfigContainer,

 
  Right,


  CallToActionContainer,
  

  DetailsLi,
  Price,
  

  GeneralDetailsHeadline,
    GeneralDetailsContainer,
    GeneralDetailsLabel,
    GeneralDetailsWrapper,
    GeneralDetailsUl,
    VisitUsContainer,
  VisitUsHead,
  VisitUsBody

} from "../styles/templates/generalStyles"
import Heart from "../components/heart"
import FilledHeart from "../components/filledHeart"
import { FavContext } from '../context/FavContext'

import SuperSwiper from "../components/Swiper"

const Template = ({ data, props }) => {
  const item = data.allWpLeuchte.nodes[0]
  const { favs, addFav, removeFav, setAdded } = useContext(FavContext)
  const [isFav, setIsFav] = useState(false)
  const itemSlides = item.postTypeAllgemeinFields.slides;
  const itemSlidesLf = itemSlides.map(slide => slide.localFile);
  const itemSlideCIS = itemSlidesLf.map(slide => slide.childImageSharp);
  const itemSlidesFluid = itemSlideCIS.map(slide => slide.fluid);
  const altText = itemSlides.map(slide => slide.altText);

  const moebel = item.tags.nodes.map(node => node.name)
  const variant = item.ausfuehrungen.nodes.map(node => node.name)

  const itemId = item.id
  const itemSlug = `/leuchten/${item.slug}`
  const itemName = moebel[0]
  const itemPrice = item.postTypeAllgemeinFields.price
  const itemVariant = variant[0]
  const itemImg = itemSlidesFluid[0]



  useEffect(() => {
    if (favs.some(fav => fav.id === itemId)) {
      setIsFav(true)
    }
  }, [isFav, favs, itemId])

  const handleAdd = (e) => {
    e.preventDefault();
    addFav(
      itemImg,
      itemName,
      itemVariant,
      itemPrice,
      itemSlug,
      itemId)
      .then(() => {
        setIsFav(true)
        setAdded(true);
      })
  }

  const handleRemove = (e) => {
    e.preventDefault();
    removeFav(itemId)
      .then(() => {
        setIsFav(false)
      })
  }

  return (
    <Layout>
      <SEO title={variant + " " + moebel}></SEO>
      <Container>
        <Wrapper>
          <SwiperContainer>
            <SuperSwiper
              alt={altText}
              slideData={itemSlides}
            />
          </SwiperContainer>
          <Right>
<div>

            <Header>
           
                <HeaderTitle>{moebel}</HeaderTitle>
       

            </Header>

            <Produktbeschreibung>
              {item.postTypeAllgemeinFields.description}
            </Produktbeschreibung>

            </div>

            <ConfigContainer>
              <GeneralDetailsHeadline>Produktdetails</GeneralDetailsHeadline>
              <GeneralDetailsContainer>
                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Abmessungen</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                  {item.postTypeAllgemeinFields.dimensions &&
                  <>

                    {item.postTypeAllgemeinFields.dimensions.map(i =>
                      <DetailsLi>
                        {i.dimension}:{i.value} cm
                                </DetailsLi>
                    )}
</>
                  }
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>

                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Materialien</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    {item.postTypeAllgemeinFields.materials &&
                      <>

                        {item.postTypeAllgemeinFields.materials.map(i =>
                          <DetailsLi>
                            {i.materialbeschreibung}
                          </DetailsLi>
                        )}
                      </>
                    }
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>
              </GeneralDetailsContainer>
            </ConfigContainer>





            <CallToActionContainer>


              {isFav === false &&
                <form onSubmit={handleAdd} >
                  <Like type="submit">
                    <Heart />
                  </Like>
                </form>
              }

              {isFav === true &&
                <form onSubmit={handleRemove} >
                  <Like type="submit">
                    <FilledHeart />
                  </Like>
                </form>
              }



              <Price>Ab {item.postTypeAllgemeinFields.price} €</Price>
            </CallToActionContainer>
          </Right>
        </Wrapper>

        <VisitUsContainer>
          <VisitUsHead>Sie haben das Richtige noch nicht gefunden?</VisitUsHead>
          <VisitUsBody>Dann besuchen Sie uns in unserem Geschäft. Wir helfen Ihnen gern dabei, genau das Richtige für Sie zu finden.</VisitUsBody>
        </VisitUsContainer>
       
      </Container>


    </Layout>
  )
}

export default Template

export const query = graphql`
query($slug: String!) {
  allWpLeuchte(filter: {slug: {eq: $slug}}) {
    nodes {
      title
      id
      slug
      seo {
        title
      }
      ausfuehrungen {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      postTypeAllgemeinFields {
        description
        price
        dimensions {
          dimension
          value
        }
        materials {
          materialbeschreibung
        }
        slides {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 750, quality: 99, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        moodimg {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
}
`